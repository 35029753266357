<template>
  <main>
    <h1>Analytics</h1>
    <div class="video-list-container">
      <div v-for="video in videoList" :key="video._id" class="list-item">
        <div>
          <label>Name</label>
          <span>{{video.name}}</span>
        </div>
        <div>
          <label>Type</label>
          <span>{{video.sourceType}}</span>
        </div>
        <div>
          <label>URL</label>
          <span>{{video.sourceUrl}}</span>
        </div>
        <div>
          <label>Created</label>
          <span>{{Math.round((Date.now() - new Date(video.createdAt).getTime()) / 1000 / 60) }} Minutes Ago</span>
        </div>
        <div>
          <label>Completed</label>
          <span>{{Math.round((Date.now() - new Date(video.completedAt).getTime()) / 1000 / 60) }} Minutes Ago</span>
        </div>
        <div>
          <label>Elapsed</label>
          <span>{{Math.round((new Date(video.completedAt).getTime() - new Date(video.createdAt).getTime()) / 1000 / 60) }} Minutes</span>
        </div>
        <div>
          <label>Duration</label>
          <span>{{Number(video.duration / 60).toFixed(1)}} Mins</span>
        </div>
        <div>
          <label>Ratio</label>
          <span>{{Math.round((new Date(video.completedAt).getTime() - new Date(video.createdAt).getTime()) / 1000) / video.duration}} Mins</span>
        </div>
        <div>
          <label>Email</label>
          <span>{{video.email}}</span>
        </div>
        <div>
          <label>Status</label>
          <span>{{video.status}}</span>
        </div>
        <div>
          <label>Progress</label>
          <span>{{video.progress}}%</span>
        </div>
        <div>
          <label>Size</label>
          <span>{{video.size}}</span>
        </div>
        <div>
          <label>Downloads</label>
          <span>{{video.downloads}}</span>
        </div>
        <div>
          <label>Download</label>
          <span><a :href="`/download/${video._id}`">Download</a></span>
        </div>
        <div>
          <pre>{{video._id}}</pre>
        </div>
      </div>
    </div>
  </main>
</template>
<script lang="ts">
  import Vue from 'vue';
  import axios from 'axios';

  export default Vue.extend({
    data: function(){
      return {
        videos: [],
        selectedVideo: null,
        startIndex: 0,
        limit: 10
      }
    },
    computed: {
      videoList(){
        if(this.videos.length) {
          const videos = this.videos.slice(Math.max(this.videos.length - this.limit, 1));
          return videos.reverse();
        }else{
          return []
        }
      }
    },
    beforeMount(){
      axios.get('/api/videos').then(results => {
        if(results.data.length){
          this.videos = results.data;
        }
      });
    }
  });
</script>
<style scoped lang="less">
  @import '../less/old';
  .list-item label{
    display: inline-block;
    width: 100px;
    font-weight: bolder;
  }
  .list-item{
    margin: 1em 0;
    border-bottom: 1px solid #444;
  }
</style>
